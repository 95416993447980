

.Header {
    text-align: center;
    justify-content: center;
    display: flex;
    gap: 16px;
    padding-top: 10px;
    padding-right: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
  }